.ft-family {
  font-family: "Poppins", sans-serif;
  font-style: normal;
}

.signIn_text {
  font-weight: 600;
  font-size: 1.3rem;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0.01em;
}
