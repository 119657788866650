.sidebar-bg {
  background: black;
  top: 80px;
}

.sidebar-links-text {
  color: #6c6c6c;
}

.sidebar-links-text:hover {
  color: white;
}

.sidebar-links-text-active {
  color: white;
}

.client-table-eng {
  height: 28rem;
}

.dashboard-summary-bar {
  height: 2px;
  background-color: black;
}

.emp-details-tabs-container {
  border-bottom: 2px solid lightgray;
}

.emp-details-tabs {
  border-bottom: 3px solid black;
}

.emp-profile {
  width: 43rem;
  margin: 1rem auto;
  /* height: 35rem; */
}

.emp-profile-bg {
  background-color: #fbf7f7;
  border-radius: 2rem;
  padding: 2rem;
  width: 20rem;
}

/* .emp-info-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  margin-left: 3rem;
}

.emp-info-title {
  margin-left: 3rem;
} */

.emp-skill {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ccc;
  padding: 2% 5%;
  background-color: #fbf7f7;
  width: 30rem;
  max-width: 20rem;
  height: 4rem;
  text-align: center;
  justify-content: space-around;
  position: relative;
  border: 1px solid lightgray;
  margin-top: 2%;
}

.emp-skill-name,
.emp-skill-id {
  margin: 3;
  
}

.emp-skill-name {
  flex: 1;
}

.emp-skill-id {
  width: 5rem;
  height: 100%;
  text-align: center;
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.emp-skill-name {
  left: 0px;
  position: absolute;
  word-wrap: break-word;
  text-align: left;
  width: 16rem;
  font-size: 90%;
  padding: 20pxpx;
}



.emp-form-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Three columns */
  gap: 30px; /* Adjust gap between columns */
  width: 100%;
}

.emp-create-profile {
  margin-top: 1rem;
}

.emplyoee-DatePicker > .react-datepicker-wrapper {
  width: 100%;
}

.user-input-error {
  border: 2px solid red;
}

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 20px;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
  border: 2px solid black;
}

.slider:before {
  position: absolute;
  content: "";
  height: 10px;
  width: 12px;
  left: 4px;
  bottom: 4px;
  background-color: black;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}

.toggle-switch > input:checked + .slider {
  background-color: white;
}

.toggle-switch > input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

.toggle-switch > input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.certificatePicker > div.react-datepicker-wrapper {
  width: 100%;
}

@media (max-width: 639px) {
  /* Add your custom styles here */
  .sidebar-bg {
    background-color: white;
    top: 0;
  }
}

.loading-indicator:before {
  content: "";
  background: #0000009b;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
}

.loading-indicator:after {
  content: "Loading";
  position: fixed;
  width: 100%;
  top: 50%;
  left: 0;
  z-index: 1001;
  text-align: center;
  font-weight: bold;
  color: white;
  font-size: 1.25rem;
}
