/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 50;
}

/* Modal Container */
.modal-container {
  background-color: white;
  border-radius: 8px;
  width: 90%; /* Adjust as needed */
  max-width: 800px; /* Maximum width */
}

/* Modal Header */
.modal-header {
  padding: 10px;
  border-bottom: 1px solid #ccc;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-header h3 {
  font-size: 20px;
  font-weight: bold;
}

.modal-header button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 18px;
}

/* Modal Body */
.modal-body {
  padding: 20px;
}

/* Modal Footer */
.modal-footer {
  padding: 10px;
  border-top: 1px solid #ccc;
  display: flex;
  justify-content: flex-end;
}

.modal-footer button {
  margin-left: 10px;
  cursor: pointer;
}

/* Media Query for Responsive Design */
@media (max-width: 768px) {
  .modal-container {
    width: 95%; /* Adjust as needed */
    max-width: none; /* Remove maximum width */
  }
}
